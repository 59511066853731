import React from 'react';
import './App.css';

// import logo from './logo.svg';
// import scene from './scene';


import Scene from './Scene';

function App() {
    return <Scene />;
}

export default App;

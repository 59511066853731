export default [
    'Agent Smith',
    'Alex Delarge',
    'Amon Goeth',
    'Annie Wilkes',
    'Anton Chigurh',
    'Bane',
    'Bellatrix Lestrange',
    'Biff Tannen',
    'Boba Fett',
    'Buffalo Bill',
    'Captain Hook',
    'Chucky',
    'Commodus',
    'Count Dooku',
    'Count Dracula',
    'Cruella de Vil',
    'Darth Maul',
    'Darth Vader',
    'Davy Jones',
    'Doctor Octopus',
    'Dolores Umbridge',
    'Dracula',
    'Dr. Evil',
    'Frankenstien',
    'Freddy Krueger',
    'Ghostface',
    'Gollum',
    'HAL 9000',
    'Hades',
    'Hannibal Lecter',
    'Hans Gruber',
    'Hans Landa',
    'Harvey Dent',
    'Jabba the Hutt',
    'Jack Torrance',
    'Jafar',
    'Jason Voorhees',
    'Jigsaw',
    'John Doe',
    'Joker',
    'Keyser Söze',
    'Khan',
    'Leatherface',
    'Loki',
    'Lord Voldemort',
    'Magneto',
    'Maleficent',
    'Michael Corleone',
    'Michael Myers',
    'Nosferatu',
    'Norman Bates',
    'Nurse Ratched',
    'Palpatine',
    'Patrick Bateman',
    'Pennywise',
    'Pinhead',
    'President Snow',
    'Professor Moriarty',
    "Ra's al Ghul",
    'Saruman',
    'Sauron',
    'Scar',
    'Scarecrow',
    'T-1000',
    'Terminator',
    'Thanos',
    'The Penguin',
    'Tommy DeVito',
    'Tyler Durden',
    'Ultron',
    'Vito Corleone',
    'Xenomorph',
    'Yautja'
];
